const mapCmsPages = new Map<string, string>();
mapCmsPages.set('cookie', 'cookie-policy');
mapCmsPages.set('cookie-policy', 'cookie');
mapCmsPages.set('privacy', 'privacy-policy');
mapCmsPages.set('privacy-policy', 'privacy');
mapCmsPages.set('servizio-clienti', 'customer-care');
mapCmsPages.set('customer-care', 'servizio-clienti');
mapCmsPages.set('termini-condizioni', 'terms-conditions');
mapCmsPages.set('terms-conditions', 'termini-condizioni');
mapCmsPages.set('pagamenti', 'payments');
mapCmsPages.set('payments', 'pagamenti');
mapCmsPages.set('resi', 'returns');
mapCmsPages.set('returns', 'resi');
mapCmsPages.set('spedizioni', 'shipments');
mapCmsPages.set('shipments', 'spedizioni');
mapCmsPages.set('shopping-online', 'shopping-online');
mapCmsPages.set('assistenza-post-vendita', 'post-sales-assistance');
mapCmsPages.set('post-sales-assistance', 'assistenza-post-vendita');
mapCmsPages.set('legal-notice', 'legal-notice');
mapCmsPages.set('traccia-ordine', 'track-order');
mapCmsPages.set('track-order', 'traccia-ordine');
mapCmsPages.set('la-nostra-storia', 'about-us');
mapCmsPages.set('about-us', 'la-nostra-storia');
mapCmsPages.set('contatti', 'contacts');
mapCmsPages.set('contacts', 'contatti');
mapCmsPages.set('whistleblowing', 'whistleblowing');

export const isCmsPage = (path: string) => mapCmsPages.has(path);

export const getOtherLocale = (path: string): string => mapCmsPages.get(path);
